<template>
  <b-card
    text-variant="center"
    class="card card-congratulations pt-2 pb-5"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="AwardIcon"
      />
    </b-avatar>
    <h1 class="mb-1 mt-50 text-white">
      Hello {{ getName }}.<br>Welcome to ITS Education Dashboard!
    </h1>
    <b-card-text class="m-auto w-75">
      This platform is still under development. If you found any issue please email it to the administrators.
    </b-card-text>

    <hr style="width: 50%; margin: 2rem auto">

  </b-card>
</template>

<script>

import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  computed: {
    getName() {
      return this.$store.state.auth.userData.name
    },
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
.csc-btn {
  min-width: 130px !important;
  font-size: 12px;

  &:hover {
    background: #eaeaea !important;
  }
}
</style>
